<template>
  <v-card id="account-setting-card">
    <!-- tabs -->
    <v-tabs
      v-model="tab"
      show-arrows
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab.icon"
      >
        <v-icon
          size="20"
          class="me-3"
        >
          {{ tab.icon }}
        </v-icon>
        <span>{{ tab.title }}</span>
      </v-tab>
    </v-tabs>

    <!-- tabs item -->
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <accounts-overview :accounts="accounts"></accounts-overview>
      </v-tab-item>

      <v-tab-item>
        <change-password :accounts="accounts"></change-password>
      </v-tab-item>

      <v-tab-item>
        <create-user @userCreated="updateAccounts"></create-user>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import {mdiAccountOutline, mdiLockOpenOutline, mdiAccountEditOutline} from '@mdi/js'
import {ref} from '@vue/composition-api'

// demos
import AccountsOverview from './AccountsOverview.vue'
import ChangePassword from './ChangePassword.vue'
import CreateUser from './CreateUser.vue'
import axios from "axios";
import config from "@/utils/config";

export default {
  components: {
    AccountsOverview,
    ChangePassword,
    CreateUser,
  },
  setup() {
    const tab = ref('')

    // tabs
    const tabs = [
      {title: 'Accounts', icon: mdiAccountOutline},
      {title: 'Password Settings', icon: mdiLockOpenOutline},
      {title: 'Create User', icon: mdiAccountEditOutline},
    ]

    return {
      accounts: null,
      tab,
      tabs,
      icons: {
        mdiAccountOutline,
        mdiLockOpenOutline,
        mdiAccountEditOutline,
      },
    }
  },
  mounted() {
    this.updateAccounts()
  },
  methods: {
    updateAccounts() {
      axios.get(`${config.SERVER_URL}/user`).then((data) => {
        this.accounts = data.data.users
      });
    }
  }
}
</script>
