<template>
  <v-card
    flat
    class="pa-3 mt-2">
    <v-card
      flat
      class="mt-5">
      <v-form>
        <div class="px-3">
          <v-card-text class="pt-5">
            <v-row>
              <v-col
                cols="12"
                sm="8"
                md="6">
                <v-alert
                  dense
                  text
                  v-show="success"
                  type="success">
                  Created new user successfully!
                </v-alert>

                <v-text-field
                  v-model="username"
                  label="Username"
                  outlined
                  :error-messages="usernameErrors"
                  dense
                ></v-text-field>

                <v-text-field
                  v-model="password"
                  type="password"
                  label="Password"
                  class="mt-3"
                  outlined
                  dense
                  :error-messages="passwordErrors"
                  hint="Make sure it's at least 8 characters."
                  persistent-hint
                ></v-text-field>

                <!-- confirm password -->
                <v-text-field
                  v-model="cPassword"
                  type="password"
                  label="Confirm New Password"
                  :error-messages="passwordMatchErrors"
                  outlined
                  dense
                  class="mt-3"

                ></v-text-field>
                <v-switch
                  v-model="admin"
                  inset
                  :error-messages="serverErrors"
                  class="mt-3"
                  label="Admin Rights"
                ></v-switch>
              </v-col>

              <v-col
                cols="12"
                sm="4"
                md="6"
                class="d-none d-sm-flex justify-center position-relative">
                <v-img
                  contain
                  max-width="170"
                  src="@/assets/images/3d-characters/pose-m-1.png"
                  class="security-character"
                ></v-img>
              </v-col>
            </v-row>
          </v-card-text>
        </div>

        <!-- divider -->

        <!-- action buttons -->
        <v-card-text>
          <v-btn
            color="primary"
            @click="create"
            class="me-3 mt-3">
            Create User
          </v-btn>
        </v-card-text>
      </v-form>
    </v-card>


  </v-card>
</template>

<script>
import {mdiEyeOffOutline, mdiEyeOutline, mdiKeyOutline, mdiLockOpenOutline} from "@mdi/js";
import axios from "axios";
import config from "@/utils/config";

export default {
  setup(props) {
    return {
      password: null,
      username: null,
      cPassword: null,
      passwordMatchErrors: null,
      passwordErrors: null,
      serverErrors: null,
      usernameErrors: null,
      success: false,
      admin: false,
      icons: {
        mdiKeyOutline,
        mdiLockOpenOutline,
        mdiEyeOffOutline,
        mdiEyeOutline,
      },
    }
  },
  watch: {
    password() {
      this.passwordMatchErrors = null
      this.passwordErrors = null
      this.serverErrors = null
    },
    cPassword() {
      this.passwordMatchErrors = null
      this.passwordErrors = null
      this.serverErrors = null
    },
    username() {
      this.usernameErrors = null
      this.serverErrors = null
    }
  },

  methods: {
    create() {
      this.success = false
      if (!this.password || this.password.length < 8) {
        this.passwordErrors = ['Password length must be at least 8 characters']
      }
      if (this.password !== this.cPassword) {
        this.passwordMatchErrors = ['Passwords does not match']
        return
      }
      axios.post(`${config.SERVER_URL}/user/create`, {
        'username': this.username,
        'password': this.password,
        'is_administrator': this.admin
      }).then((data) => {
        if (data.data.success) {
          this.username = null
          this.password = null
          this.cPassword = null
          this.passwordMatchErrors = null
          this.success = true
          this.$emit('userCreated')
        } else {
          this.usernameErrors = data.data.errmsg
        }
      }).catch((err) => {
        this.serverErrors = ['A server error happened. Status code: ' + err.status]
        console.log(err)
      })
    }
  }
}
</script>
