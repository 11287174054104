<template>
  <v-card
    flat
    class="pa-3 mt-2">
    <v-card-text class="d-flex">
      <v-simple-table style="min-width: 300px">
        <template v-slot:default>
          <thead>
          <tr>
            <th class="">
              Username
            </th>
            <th class="">
              Admin
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
            v-for="account in accounts"
            :key="account.username">
            <td class="">
              <strong>{{ account.username }}</strong>
            </td>
            <td class="">
              {{ account.is_admin }}
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import {mdiAlertOutline, mdiCloudUploadOutline} from '@mdi/js'
import {ref} from '@vue/composition-api'
import axios from 'axios'
import config from "@/utils/config";

export default {
  props: ['accounts'],
  setup(props) {
    return {
      status,
      accounts: null,
      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
      },
    }
  },
}
</script>
