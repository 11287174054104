<template>
  <v-card
    flat
    class="mt-5"
  >
    <v-form>
      <div class="px-3">
        <v-card-text class="pt-5">
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="6"
            >
              <v-alert
                dense
                text
                v-show="success"
                type="success">
                Changed password!
              </v-alert>

              <v-select
                v-model="username"
                hint="User which password should be changed"
                :items="users"
                label="Username"
                persistent-hint
                :error-messages="usernameErrors"
                return-object
                outlined
                single-line
              ></v-select>

              <!-- new password -->
              <v-text-field
                v-model="password"
                :type="isNewPasswordVisible ? 'text' : 'password'"
                :append-icon="isNewPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                label="New Password"
                outlined
                dense
                :error-messages="passwordErrors"
                class="mt-3"
                hint="Make sure it's at least 8 characters."
                persistent-hint
                @click:append="isNewPasswordVisible = !isNewPasswordVisible"
              ></v-text-field>

              <!-- confirm password -->
              <v-text-field
                v-model="cPassword"
                :type="isCPasswordVisible ? 'text' : 'password'"
                :append-icon="isCPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                label="Confirm New Password"
                outlined
                :error-messages="passwordMatchErrors"
                dense
                class="mt-3"
                @click:append="isCPasswordVisible = !isCPasswordVisible"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              sm="4"
              md="6"
              class="d-none d-sm-flex justify-center position-relative"
            >
              <v-img
                contain
                max-width="400"
                src="@/assets/images/3d-characters/error.png"
                class="security-character"
              ></v-img>
            </v-col>
          </v-row>
        </v-card-text>
      </div>

      <!-- divider -->

      <!-- action buttons -->
      <v-card-text>
        <v-btn
          color="primary"
          @click="changePassword"
          class="me-3 mt-3">
          Save changes
        </v-btn>
      </v-card-text>
    </v-form>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {mdiKeyOutline, mdiLockOpenOutline, mdiEyeOffOutline, mdiEyeOutline} from '@mdi/js'
import {ref} from '@vue/composition-api'
import axios from "axios";
import config from "@/utils/config";

export default {
  props: ['accounts'],
  watch: {
    password() {
      this.passwordMatchErrors = null
      this.passwordErrors = null
      this.serverErrors = null
    },
    cPassword() {
      this.passwordMatchErrors = null
      this.passwordErrors = null
      this.serverErrors = null
    },
  },
  setup() {
    const isNewPasswordVisible = ref(false)
    const isCPasswordVisible = ref(false)
    const password = null
    const cPassword = null
    const username = null
    const accounts = null;

    return {
      isNewPasswordVisible,
      accounts,
      username,
      isCPasswordVisible,
      password,
      passwordMatchErrors: null,
      passwordErrors: null,
      serverErrors: null,
      usernameErrors: null,
      success: false,
      cPassword,
      icons: {
        mdiKeyOutline,
        mdiLockOpenOutline,
        mdiEyeOffOutline,
        mdiEyeOutline,
      },
    }
  },
  computed: {
    users() {
      if (this.accounts == null) {
        return []
      } else {
        return this.accounts.map(function (item) {
          return item.username
        })
      }
    }
  },
  methods: {
    changePassword() {
      this.success = false
      if (!this.password || this.password.length < 8) {
        this.passwordErrors = ['Password length must be at least 8 characters']
      }
      if (this.password !== this.cPassword) {
        this.passwordMatchErrors = ['Passwords does not match']
        return
      }
      axios.post(`${config.SERVER_URL}/user/password`, {
        'username': this.username,
        'password': this.password
      }).then((data) => {
        if (data.data.success) {
          this.username = null
          this.password = null
          this.cPassword = null
          this.passwordMatchErrors = null
          this.success = true
        } else {
          this.serverErrors = data.data.errmsg
        }
      }).catch((err) => {
        console.log('password change does not work')
        this.serverErrors = ['A server error happened. Status code: ' + err.status]
        console.log(err)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.two-factor-auth {
  max-width: 25rem;
}

.security-character {
  position: absolute;
  bottom: -0.5rem;
}
</style>
